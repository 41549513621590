import React from 'react';
import Layout from '../../layout/layout';
import ArticleList from '../../components/blog/articlelist';
import Heading from '../../components/heading/heading';

import { withTranslation, Trans } from 'react-i18next';
import Seo from '../../layout/seo';
import { graphql } from 'gatsby';


class BlogArticles extends React.Component {

  render() {
    const { t } = this.props;
    return (
      <Layout location={this.props.location}>
        <Seo lang="de" title="Nutrfy Blog" location={this.props.location} />
        <Heading as="h1" className="page">{t('Knowledge.blog_headline')}</Heading>

        <Trans i18nKey='Knowledge.blog_intro'><p>Read all you ever wanted to know about food, nutrition and sports right here in one spot.</p></Trans>
        <ArticleList items={this.props.data.allMarkdownRemark.edges} location={this.props.location}/>

      </Layout>
      
    )
  }
}
export default withTranslation()(BlogArticles);

export const pageQuery = graphql`
    query {
      allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            html
            frontmatter {
              date
              path
              title
            }
          }
        }
      }
    }`